import { Button, Container, Typography } from "@mui/material";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ReviewsIcon from "@mui/icons-material/Reviews";
import main from "../../assets/scss/main.module.scss";

type Props = {
  type: string;
  position: { lat: number; lng: number };
  setIsRateOpen: (args: boolean) => void;
};

export const ButtonMenu = ({ type, position, setIsRateOpen }: Props) => {
  const googleMapDestUrl = (lat: number, lng: number) => {
    const destUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    return destUrl;
  };

  return (
    <Container
      sx={{
        my: 2,
        display: "flex",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <Button
        variant="text"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <a
          href={googleMapDestUrl(position.lat, position.lng)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <AssistantDirectionIcon
            fontSize="large"
            sx={{ color: main.colorDarkGreen }}
          />
          <Typography>ここへ行く</Typography>
        </a>
      </Button>
      {type === "restaurant" && (
        <Button
          variant="text"
          onClick={() => setIsRateOpen(true)}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ReviewsIcon fontSize="large" sx={{ color: main.colorDarkGreen }} />
          <Typography>評価する</Typography>
        </Button>
      )}
    </Container>
  );
};
