import { Container, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";

import { issuerDetailData } from "../../type";
import main from "../../assets/scss/main.module.scss";

export const Info = ({
  issuerDetailData,
}: {
  issuerDetailData: issuerDetailData;
}) => {
  const editDateFormat = (dateStr?: string): string => {
    if (dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString("ja-JP");
    } else {
      return "";
    }
  };

  const textToLink = (comment: string) => {
    const regexpUrl = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
    let linkedComment = comment.replace(
      regexpUrl,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    return linkedComment;
  };

  return (
    <>
      <Typography variant="h6" fontWeight="bold">
        基本情報
      </Typography>
      <Container
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          my: 2,
        }}
      >
        {issuerDetailData.address && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PlaceIcon sx={{ color: main.colorDarkGreen }} />
            {issuerDetailData.address}
          </Container>
        )}
        {issuerDetailData.tel && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PhoneIcon sx={{ color: main.colorDarkGreen }} />
            {issuerDetailData.tel}
          </Container>
        )}
        {issuerDetailData.email && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <EmailIcon sx={{ color: main.colorDarkGreen }} />
            {issuerDetailData.email}
          </Container>
        )}
        {issuerDetailData.web && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LanguageIcon sx={{ color: main.colorDarkGreen }} />
            {issuerDetailData.web}
          </Container>
        )}
        {issuerDetailData.businessHour && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <AccessTimeIcon sx={{ color: main.colorDarkGreen }} />
            {issuerDetailData.businessHour}
          </Container>
        )}
        {(issuerDetailData.eventStartDate || issuerDetailData.eventEndDate) && (
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CalendarMonthIcon sx={{ color: main.colorDarkGreen }} />
            {issuerDetailData.eventStartDate ===
              issuerDetailData.eventEndDate && (
              <>{editDateFormat(issuerDetailData.eventStartDate)}</>
            )}
            {issuerDetailData.eventStartDate !==
              issuerDetailData.eventEndDate && (
              <>
                {editDateFormat(issuerDetailData.eventStartDate)} ~{" "}
                {editDateFormat(issuerDetailData.eventEndDate)}
              </>
            )}
          </Container>
        )}
      </Container>
      {issuerDetailData.description && (
        <>
          <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
            詳細情報
          </Typography>
          <Container
            sx={{
              whiteSpace: "break-spaces",
              px: 2,
              overflowWrap: "anywhere",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: textToLink(issuerDetailData.description),
              }}
            ></div>
          </Container>
        </>
      )}
      {issuerDetailData.type === "event" && (
        <>
          <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
            出店者一覧
          </Typography>
          {issuerDetailData.eventStoreList.map((name: string, index) => (
            <Container key={index} sx={{ my: 1 }}>
              {name}
            </Container>
          ))}
        </>
      )}
    </>
  );
};
