import { useNavigate } from "react-router-dom";
import { IconButton, Paper, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const Title = ({ name }: { name?: string }) => {
  const navigate = useNavigate();

  return (
    <Paper elevation={0} sx={{ my: 2, position: "relative" }}>
      <IconButton
        color="primary"
        size="large"
        onClick={() => navigate("/map", { state: { noReload: true } })}
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          my: "auto",
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Typography
        variant="h5"
        width="100%"
        sx={{ px: "50px", wordBreak: "break-all" }}
      >
        {name ?? "読込中..."}
      </Typography>
    </Paper>
  );
};
