import React from "react";
import ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "@aws-amplify/ui-react/styles.css";
import "./assets/scss/main.scss";
import main from "./assets/scss/main.module.scss";
import { App } from "./App";
import { CustomAuthenticator } from "./components/Auth";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "@fontsource/noto-sans-jp";

serviceWorkerRegistration.unregister();

const theme = createTheme({
  palette: {
    primary: { main: main.colorDarkGreen },
    secondary: { main: main.colorLightGreen },
    info: { main: main.colorOrange },
    success: { main: main.colorGreen },
  },
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CustomAuthenticator>
        <App />
      </CustomAuthenticator>
    </ThemeProvider>
  </React.StrictMode>
);
