import { useState } from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const Images = ({ urls }: { urls?: string[] }) => {
  const [zoomImgIndex, setZoomImgIndex] = useState<number>();

  return (
    <>
      {urls && (
        <>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={true}
            pagination={{
              clickable: true,
            }}
          >
            {urls.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={item}
                  alt=""
                  style={{
                    maxHeight: "500px",
                    maxWidth: "500px",
                    width: "100%",
                    objectFit: "contain",
                    aspectRatio: "1/1",
                  }}
                  onClick={() => setZoomImgIndex(index)}
                  loading="lazy"
                />
                <div className="swiper-lazy-preloader" />
              </SwiperSlide>
            ))}
          </Swiper>
          <Modal
            open={zoomImgIndex !== undefined}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                },
              },
            }}
          >
            <>
              <IconButton
                onClick={() => setZoomImgIndex(undefined)}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "#fff",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  zIndex: 99,
                  m: 1,
                }}
              >
                <CloseIcon fontSize="large" sx={{ fontSize: "48px" }} />
              </IconButton>
              <Swiper
                initialSlide={zoomImgIndex!}
                modules={[Navigation, Pagination, Zoom]}
                navigation={true}
                pagination={{ type: "fraction" }}
                zoom={true}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  color: "#fff",
                }}
              >
                {urls.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="swiper-zoom-container">
                      <img
                        src={item}
                        alt=""
                        style={{
                          maxHeight: "100vh",
                          maxWidth: "100vw",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          </Modal>
        </>
      )}
    </>
  );
};
