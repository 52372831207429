import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Divider, Typography } from "@mui/material";

import { ButtonMenu } from "../components/mapDetail/ButtonMenu";
import { Images } from "../components/mapDetail/Images";
import { Info } from "../components/mapDetail/Info";
import { Rating } from "../components/mapDetail/Rating";
import { SendRate } from "../components/SendRate";
import { Title } from "../components/mapDetail/Title";
import CustomAxios from "../utils/CustomAxios";
import { issuerDetailData } from "../type";

export const MapDetail = () => {
  const { issuerId } = useParams();

  const [issuerDetailData, setIssuerDetailData] = useState<issuerDetailData>();
  const [selectRate, setSelectRate] = useState<number>();
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);

  useEffect(() => {
    CustomAxios.get(`/issuer/${issuerId}`)
      .then((res) => {
        setIssuerDetailData(res.data as issuerDetailData);
        setSelectRate(res.data.customerRate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [issuerId]);

  return (
    <>
      <Title name={issuerDetailData?.name} />
      {issuerDetailData && (
        <Container sx={{ width: "100%", maxWidth: "500px", mx: "auto", px: 0 }}>
          <Images urls={issuerDetailData.urls} />
          <Container>
            <Typography>
              この{issuerDetailData.type === "event" ? "イベント" : "お店"}
              はこれまでに
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
                mt: 1,
                mx: "auto",
                width: "150px",
                borderBottom: 1,
                borderColor: "#FF0000",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {(
                  Math.round((issuerDetailData.totalReduleCo2 / 1000) * 10) / 10
                ).toLocaleString()}
              </Typography>
              <Typography fontSize="14px" sx={{ ml: 1 }}>
                kg-CO2
              </Typography>
            </Box>
            <Typography>の削減に貢献しています</Typography>
          </Container>
          <Divider sx={{ my: 2, mx: "auto", width: "80%" }} />
          {!isRateOpen && (
            <ButtonMenu
              type={issuerDetailData.type}
              position={issuerDetailData.position}
              setIsRateOpen={setIsRateOpen}
            />
          )}
          {isRateOpen && (
            <SendRate
              issuerId={issuerId}
              selectRate={selectRate}
              setSelectRate={setSelectRate}
              setIsRateOpen={setIsRateOpen}
            />
          )}
          <Divider sx={{ my: 2, mx: "auto", width: "80%" }} />
          {issuerDetailData.type === "restaurant" && (
            <Rating averageRate={issuerDetailData.averageRate} />
          )}
          <Info issuerDetailData={issuerDetailData} />
        </Container>
      )}
    </>
  );
};
