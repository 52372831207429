import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import StoreIcon from "@mui/icons-material/Store";

import { SendRate } from "../../components/SendRate";
import CustomAxios from "../../utils/CustomAxios";
import main from "../../assets/scss/main.module.scss";
import { useNoticeContext } from "../../context/NoticeContext";
import paymentSound from "../../assets/sound/payment.mp3";

const PAYMENT_SOUND = new Audio(paymentSound);

type Props = {
  issuerId: string;
  issuerName: string;
  setIssuerName: (arg: string) => void;
  issuerType: string;
  setIsInputAreaOpen: (args: boolean) => void;
  setIsConfirmOpen: (args: boolean) => void;
  inputPay: string;
  getCustomerInfo: () => void;
  selectRate: number | undefined;
  setSelectRate: (arg: number) => void;
};

export const Confirm = ({
  issuerId,
  issuerName,
  setIssuerName,
  issuerType,
  setIsInputAreaOpen,
  setIsConfirmOpen,
  inputPay,
  getCustomerInfo,
  selectRate,
  setSelectRate,
}: Props) => {
  const navigate = useNavigate();
  const { setNotice, setSeverity } = useNoticeContext();

  const [point, setPoint] = useState<number | null>(null);
  const [isSendLoading, setIsSendLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onSend = () => {
    setIsSendLoading(true);
    CustomAxios.post(`/customer/issuer/${issuerId}`, {
      value: Number(inputPay),
    })
      .then((res) => {
        if (res.data?.result === "Success") {
          setIssuerName(res.data.issuerName);
          setPoint(res.data.point);
          setIsModalOpen(true);
          getCustomerInfo();

          // 決済成功音を鳴らす
          PAYMENT_SOUND.currentTime = 0;
          PAYMENT_SOUND.play();
        } else {
          setNotice("QRコードが不正です");
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSendLoading(false);
      });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <>
      <div className="issuerName">
        <StoreIcon
          fontSize="large"
          sx={{
            p: "10px",
            backgroundColor: main.colorGreen,
            color: "#fff",
            borderRadius: "50%",
            width: "75px",
            minWidth: "75px",
            maxWidth: "75px",
            height: "75px",
            minHeight: "75px",
            maxHeight: "75px",
          }}
        />
        <span>{issuerName}</span>
      </div>
      <Paper
        elevation={0}
        sx={{ my: "50px", fontWeight: "bold", fontSize: "56px" }}
      >
        ￥{Number(inputPay).toLocaleString()}
      </Paper>
      <Paper
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          m: 2,
          pb: 2,
        }}
        elevation={0}
      >
        {isSendLoading && <CircularProgress />}
        {!isSendLoading && (
          <>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={() => {
                setIsConfirmOpen(false);
                setIsInputAreaOpen(true);
              }}
              startIcon={<ArrowBackIcon />}
              sx={{ mb: 2 }}
            >
              戻る
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={onSend}
              endIcon={<CurrencyYenIcon />}
            >
              支払う
            </Button>
          </>
        )}
      </Paper>
      <Modal open={isModalOpen}>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            m: 2,
            bgcolor: "background.paper",
            borderRadius: "16px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="primary"
            sx={{
              textAlign: "center",
              p: 1,
              backgroundColor: main.colorGreen,
              color: "#fff",
              borderRadius: "16px 16px 0 0",
            }}
          >
            支払い完了
          </Typography>
          <div className="issuerName">
            <StoreIcon
              fontSize="medium"
              sx={{
                p: "10px",
                backgroundColor: main.colorGreen,
                color: "#fff",
                borderRadius: "50%",
                width: "50px",
                minWidth: "50px",
                maxWidth: "50px",
                height: "50px",
                minHeight: "50px",
                maxHeight: "50px",
              }}
            />
            <span>{issuerName}</span>
          </div>
          <Grid
            container
            sx={{ textAlign: "center", alignItems: "center", p: 2 }}
          >
            <Grid item xs={6}>
              <Typography variant="h4">
                ￥{Number(inputPay).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ textAlign: "center" }}>付与ポイント</Typography>
              <Paper elevation={0} sx={{ textAlign: "center", mt: 1 }}>
                <Chip
                  label={`+${point}pt`}
                  color="info"
                  sx={{ color: "#FFF", px: 2 }}
                ></Chip>
              </Paper>
            </Grid>
          </Grid>
          {(issuerType === "resutaurant" || issuerType === "event") && (
            <Container sx={{ mt: 4, textAlign: "center" }}>
              <Typography>このお店を評価する</Typography>
              <SendRate
                issuerId={issuerId}
                selectRate={selectRate}
                setSelectRate={setSelectRate}
              />
            </Container>
          )}
          <Paper sx={{ ml: 2, mr: 2, mb: 2, mt: 10 }}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={handleClose}
            >
              OK
            </Button>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
