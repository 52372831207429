import { Container, Typography } from "@mui/material";

export const Rating = ({ averageRate }: { averageRate?: number }) => {
  const calcRatePercent = (rate?: number): number => {
    return rate ? (rate / 5) * 100 : 0;
  };

  return (
    <>
      <Typography variant="h6" fontWeight="bold">
        評価
      </Typography>
      <Container
        sx={{
          my: 2,
          display: "flex",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {averageRate && (
          <Typography
            variant="h5"
            sx={{
              background: `linear-gradient(to right, #f8b500 ${calcRatePercent(
                averageRate
              )}%, gray ${calcRatePercent(averageRate)}%)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            ★★★★★
          </Typography>
        )}
        {!averageRate && <Typography>まだ評価はありません</Typography>}
      </Container>
    </>
  );
};
